.sidebar-width {
    width: 215px;
}

.sidebar-width nav a {
    color: #000 !important;
    font-size: 15px !important;
    padding: 12px 15px !important;
}
.sidebar nav a {
    font-size: 14px;
    padding: 10px 10px;
}
.sidebar .flex-col::-webkit-scrollbar-thumb {
    background: #f27d00;
}
.sidebar .flex-col::-webkit-scrollbar {
    width: 4px;
}
ul.btn-toggle-nav li a
{
    padding: 0;
}
div#orders-collapse {
    margin: 10px 0;
}
ul.btn-toggle-nav li a.active {
    background: transparent !important;
    color: #f27d00 !important;
    width: 100%;
    display: block;
    border-radius: 0 !important;
}
ul.btn-toggle-nav li a:hover {
    background: transparent !important;
}
ul.btn-toggle-nav li a.active:hover {
    background: transparent !important;
    color: #f27d00 !important;
}
ul.btn-toggle-nav {
    list-style: circle;
    background: #f9f9f9;
    padding: 8px;
    border-radius: 10px;
    padding-left: 40px !important;
    position: relative;
}
div#orders-collapse a:hover {
    background: transparent !important;
}
div#orders-collapse a {
    padding: 0 !important;
    color: #b7b7b7 !important;
    font-size: 14px !important;
}

div#orders-collapse ul li:hover {
    list-style: disc !important;
}
div#orders-collapse ul li:hover a {
    color: #000 !important;
}
div#orders-collapse ul li {
    padding: 4px 0px;
}

.sidebar-width nav ul li ul li a:hover, .sidebar-width nav ul li ul li  a.active {
    background: transparent !important;
    color: #ef5e15 !important;
}

.sidebar-over::-webkit-scrollbar {
    width: 0 !important;
}
.sidebar-width ul li ul li a {
    padding: 0!important;
}

.sidebar-width ul li ul li a {
    padding: 0!important;
    font-size: 14px !important;
    color: gray !important;
}

.sidebar-width ul li ul li {
    padding: 3px 0;
}

.sidebar-width ul li ul li:hover {
    list-style: disc !IMPORTANT;
}

.sidebar-width ul li ul li:hover::marker {
    color: #ef5e15 !important;
}