.img-box {
    width: 100px;
    height: 100px;
    border: 1px solid #cbcaca;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
}
.vb input.search-text {
    width: 65px!important;
}
.search-form-banner .col {
    margin-right: 12px !important;
}
.search-form-banner {
   align-items: end !important;
}
.side-box {
    width: 100%;
    border: 1px solid;
    padding: 14px;
    text-align: center;
}

img.chooseImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
}