.table-title
{
    width: max-content;
}
.page span {
    border: 1px solid #B3B8BD;
    color: #B3B8BD;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-radius: 50%;
}

.page.number span {
    color: #FE5E00;
}

.page.number.active span {
    color: white;
    background-color: #FE5E00;
}

.page span:hover {
    border: 1px solid black;
    color: black;
}