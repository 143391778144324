
@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.technician-check input {
  width: 18px !important;
}
.table-up-del {
  display: flex;
}
.table-up-del button {
  padding: 14px 20px !important;
  margin: 0 2px !important;
}
button.border-btn:hover {
  color: #fff !important;
  background: #f27d00 !important;
}
button.border-btn.edit {
  padding: 7px 20px !important;
}
button.border-btn {
  border: 1px solid #f27d00;
  background: transparent !important;
  color: #f27d00 !important;
}
.btn-md
{
    padding: 14px 40px !important;
    font-size: 15px !important;
}
.create-table-invoice input {
  padding-left: 10px !important;
}

.bg-gray
{
  background-color: #f4f5f9;
}

.f-14
{
  font-size: 14px;
}

.f-15
{
  font-size: 15px;
}
.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.checkbox > input {
  height: 21px;
  width: 21px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-radius: 4px;
  border: 1px solid #ccc;
  transition-duration: 0.3s;
  background-color: #ffffff;
  cursor: pointer;
  margin-right: 10px;
}

.checkbox > input:checked {
  background-color: #f27d00;
  border: 1px solid #f27d00;
}
.gray 
{
  color: #7f7f7f;
}
.top-search-form input.search-text {
  padding: 12px 15px;
  border-radius: 33px;
  margin-right: 10px;
}
.top-search-form i {
  position: absolute;
  right: 22px;
  top: 13px;
  color: #707488;
  font-size: 22px;
}
.top-search-form {
  position: relative;
  margin-right: 30px;
}

.top-form-wrap {
  display: flex;
  align-items: center;
  position: absolute;
  right: 16px;
  top: 41px;
}
.bg-theme
{
  background-color: #f4f5f9 !important;
}
.border-btm {
  border-bottom: 2px solid #f27d00 !IMPORTANT;
}
.online-stores-check span {
  margin-left: 10px;
  font-size: 14px;
}
.checkbox > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  color: #ffffff;
  position: absolute;
  left: 6px;
  top: 2px;
  font-size: 13px;
}

.checkbox > input:active {
  border: 2px solid #f27d00;
}

.bold
{
  font-weight: 700;
}

.sidebar nav a:hover {
  background: #f27d00 !important;
  border-radius: 0 !important;
}
.bg-orange
{
  background: #f27d00 !important;
}
a{
  text-decoration: none !important;
}
.f-13
{
  font-size: 13px;
}
.f-20
{
  font-size: 20px;
}
.f-16
{
  font-size: 16px;
}
.orange {
  color: #f27d00;
}
.card-content {
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 7px 4px #e5e5e5;
  border-radius: 8px;
}
.semibold
{
  font-weight: 600;
}

.dashboard-cards .card {
  width: 24%;
  padding-right: 14px;
  border-radius: 10px;
  background: transparent;
  margin-bottom: 15px;
}

.dashboard-cards  h3 {
  min-height: 42px;
}

a.menu-link {
  padding: 10px 14px;
}

input:focus
{
  outline: none !important;
}