@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

$primary: #f27d00;
$secondary: #FFC6D3;
$btn-border-radius-lg: 0px;
$btn-border-radius-sm: 0px;
$btn-border-radius: 0px;
$btn-padding-y-lg: 16px;
$btn-padding-x-lg: 50px;
$btn-padding-y-sm: 11px;
$btn-padding-x-sm: 40px;
$font-family-sans-serif: "Poppins";
$btn-font-weight: 400;
$btn-font-size-lg: 14px;
$btn-font-size-sm: 14px;
$btn-border-width: 1px;


.input-field {
    height: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 12px;
    padding: 2%;
    width: 100%;
    margin-top: 1%;
}

span.password-icon {
    position: absolute;
    top: 17px;
    right: 15px;
    cursor: pointer;
}

span.icon {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid grey;
    color: grey;
    font-weight: 300;
    border-radius: 28%;
    padding: 14px;
}

span.icon:hover {
    transform: scale(1.1);
    border: 1px solid black;
    color: black;
}

select.form-select {
    height: 50px;
    margin-top: 7px;
    border-radius: 1px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.col-2-3 {
    flex: 0 0 auto !important;
    width: 23% !important;
}

.w-70 {
    width: 70% !important;
}

.fw-200 {
    font-weight: 200;
}

.sidebar nav a.active {
    background: #f27d00 !important;
    border-radius: 0 !important;
    color: white !important;
}

.view-field {
    display: flex;
    justify-content: space-between;
}

.view-field p:nth-child(2) {
    text-align: right;
}

@media (min-width: 640px) {
    .size-medium .sm\:max-w-lg {
        max-width: 32rem;
    }

    .size-large .sm\:max-w-lg {
        max-width: 60rem;
    }
}


@import '~bootstrap/scss/bootstrap.scss';